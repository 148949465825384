import React from "react";
import { ErrorIconProps } from "./error.props";

const ErrorIcon = ({ className, style }: ErrorIconProps) => {
  return (
    <div className={className} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="161"
        height="160"
        fill="none"
        viewBox="0 0 161 160"
      >
        <path
          fill="#F2F9FC"
          d="M4.914 80c0 58.968 63.834 95.822 114.902 66.338A76.6 76.6 0 00158.116 80c0-58.968-63.834-95.822-114.901-66.338A76.6 76.6 0 004.915 80z"
        ></path>
        <path
          fill="#D6E7FF"
          d="M40.898 125.6c0 2.46 32.834 3.997 59.1 2.767 12.191-.571 19.7-1.626 19.7-2.767 0-2.46-32.833-3.997-59.1-2.767-12.19.571-19.7 1.625-19.7 2.767z"
        ></path>
        <path
          fill="#fff"
          d="M35.621 48.65c0-8.837 7.163-16 16-16h57.681c8.836 0 16 7.163 16 16v55.449h-89.68v-55.45z"
        ></path>
        <path
          stroke="#222A3F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M35.621 48.65c0-8.837 7.163-16 16-16h57.681c8.836 0 16 7.163 16 16v55.449h-89.68v-55.45z"
        ></path>
        <path
          fill="#5CC4ED"
          d="M118.352 32.65h-75.78a6.95 6.95 0 00-6.95 6.951v9.582h89.68V39.6a6.95 6.95 0 00-6.95-6.952z"
        ></path>
        <path
          stroke="#222A3F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M118.352 32.65h-75.78a6.95 6.95 0 00-6.95 6.95v9.583h89.68v-9.584a6.95 6.95 0 00-6.95-6.95z"
        ></path>
        <path
          fill="#fff"
          stroke="#222A3F"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M42.57 40.872c-.005 2.676 2.887 4.355 5.208 3.022a3.476 3.476 0 001.744-3.022c.006-2.675-2.886-4.355-5.207-3.022a3.477 3.477 0 00-1.745 3.022zM56.469 40.872c-.006 2.676 2.887 4.355 5.207 3.022a3.475 3.475 0 001.745-3.022c.006-2.675-2.887-4.355-5.207-3.022a3.476 3.476 0 00-1.745 3.022zM70.371 40.872c-.006 2.676 2.887 4.355 5.208 3.022a3.476 3.476 0 001.744-3.022c.006-2.675-2.887-4.355-5.207-3.022a3.476 3.476 0 00-1.745 3.022z"
        ></path>
        <path
          fill="#fff"
          stroke="#222A3F"
          strokeWidth="1.5"
          d="M55.234 67.489c0 2.642 2.86 4.294 5.149 2.972a3.432 3.432 0 001.716-2.972c0-2.642-2.86-4.294-5.148-2.973a3.432 3.432 0 00-1.717 2.973zM98.71 67.489c0 2.642 2.861 4.294 5.149 2.972a3.433 3.433 0 001.717-2.972c0-2.642-2.861-4.294-5.149-2.973a3.432 3.432 0 00-1.716 2.973z"
        ></path>
        <path
          fill="#5CC4ED"
          stroke="#222A3F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M108.844 82.525c.064 2.516-2.621 4.158-4.832 2.954a3.27 3.27 0 01-1.706-2.954c0-1.806 3.27-8.172 3.27-8.172s3.268 6.366 3.268 8.172z"
        ></path>
        <path
          fill="#fff"
          stroke="#222A3F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M70.58 92.141a2.55 2.55 0 01-2.473-3.176c2.415-9.465 14.171-12.765 21.16-5.94a12.69 12.69 0 013.43 5.94 2.55 2.55 0 01-2.473 3.176 60.512 60.512 0 00-9.821-1.268 60.491 60.491 0 00-9.823 1.268z"
        ></path>
        <path
          fill="#F04C34"
          stroke="#222A3F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M45.756 119.081a4.86 4.86 0 01-3.457-1.433l-6.528-6.528-6.527 6.528c-2.662 2.661-7.206 1.442-8.18-2.193a4.89 4.89 0 011.267-4.722l6.528-6.529-6.528-6.53c-2.598-2.723-1.273-7.237 2.384-8.125a4.889 4.889 0 014.529 1.214l6.527 6.53 6.528-6.53c2.66-2.661 7.205-1.444 8.18 2.19a4.889 4.889 0 01-1.265 4.723l-6.53 6.53 6.53 6.527c2.66 2.662 1.44 7.206-2.195 8.178-.412.11-.837.166-1.263.166v.004z"
        ></path>
      </svg>
    </div>
  );
}

export default ErrorIcon;
