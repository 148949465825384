import React from "react";
import { SearchIconProps } from "./search.props";

const SearchIcon = ({className, style }: SearchIconProps) => {
    return (
        <div className={className} style={style}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="160"
                height="160"
                fill="none"
                viewBox="0 0 160 160"
            >
                <path
                    fill="#F2F9FC"
                    d="M2.385 80c0 59.749 64.68 97.092 116.424 67.217A77.616 77.616 0 00157.617 80c0-59.749-64.68-97.092-116.424-67.217A77.616 77.616 0 002.385 80z"
                ></path>
                <path
                    fill="#D6E7FF"
                    d="M93.404 139.07c0 1.86-11.06 3.37-24.709 3.37s-24.709-1.511-24.709-3.37c0-1.859 11.06-3.369 24.71-3.369 13.648 0 24.708 1.508 24.708 3.369z"
                ></path>
                <path
                    fill="#fff"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M94.893 96.358l9.25-9.252 10.788 10.788-9.25 9.252-10.788-10.788z"
                ></path>
                <path
                    fill="#5CC4ED"
                    d="M136.511 116.388c4.746 4.746 2.575 12.85-3.907 14.587a8.718 8.718 0 01-8.422-2.255l-18.494-18.496a2.178 2.178 0 010-3.082l9.24-9.248a2.179 2.179 0 013.083 0l18.5 18.494z"
                ></path>
                <path
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M136.511 116.388c4.746 4.746 2.575 12.85-3.907 14.587a8.718 8.718 0 01-8.422-2.255l-18.494-18.496a2.178 2.178 0 010-3.082l9.24-9.248a2.179 2.179 0 013.083 0l18.5 18.494z"
                ></path>
                <path
                    fill="#5CC4ED"
                    d="M22.924 60.906c-.02 35.236 38.11 57.28 68.635 39.681a45.773 45.773 0 0022.909-39.68c.021-35.236-38.11-57.28-68.635-39.68a45.772 45.772 0 00-22.91 39.68z"
                ></path>
                <path
                    fill="#fff"
                    d="M60.433 95.908c26.944 6.36 50.67-18.832 42.706-45.347a35.963 35.963 0 00-26.181-24.656c-26.944-6.36-50.67 18.832-42.706 45.347a35.963 35.963 0 0026.181 24.656z"
                ></path>
                <path
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M60.433 95.908c26.944 6.36 50.67-18.832 42.706-45.347a35.963 35.963 0 00-26.181-24.656c-26.944-6.36-50.67 18.832-42.706 45.347a35.963 35.963 0 0026.181 24.656z"
                ></path>
                <path
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M22.924 60.906c-.02 35.236 38.11 57.28 68.635 39.681a45.773 45.773 0 0022.909-39.68c.021-35.236-38.11-57.28-68.635-39.68a45.772 45.772 0 00-22.91 39.68z"
                ></path>
            </svg>
        </div>
    );
}

export default SearchIcon;