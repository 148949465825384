import React, { ReactElement } from 'react';

import Button from 'ui-kit/button/button';
import ErrorIcon from 'ui-kit/icons/discount-card/error/error';
import SearchIcon from 'ui-kit/icons/discount-card/search/search';
import ToastBox from 'ui-kit/toast-box/toast-box';

import AddressBox from 'components/address-box';
import PrescriptionSearch from 'components/prescription-search';

import { ErrorOrNoResultComponentProps } from './prescription-error-or-no-results.props';
import './prescription-error-or-no-results.style.scss';

const PrescriptionErrorOrNoResults = (props: ErrorOrNoResultComponentProps): ReactElement => {
    return (
        <div className="no-drug-results">
            <div className="no-drug-results__image">
                {props.variant === 'not-found' ? <SearchIcon /> : <ErrorIcon />}
            </div>
            <div className="no-drug-results__info">
                <h3>{props.title}</h3>
                <p>{props.subTitle}</p>
            </div>
            {props.variant === 'not-found' && props.onChangeAddress && (
                <div className="no-drug-results__search-box">
                    {props.hasLocationError && !props.hasLocation && (
                        <div className="no-drug-results__search-box-warning">
                            <ToastBox icon="warning" variant="warning">
                                <p>{props.locationError}</p>
                            </ToastBox>
                        </div>
                    )}
                    <AddressBox
                        variation={props.hasLocation ? 'default' : 'noLocation'}
                        locationAddress={props.locationAddress}
                        onChangeAddress={props.onChangeAddress}
                    />
                    <div className="no-drug-results__search-box__search-bar">
                        <PrescriptionSearch
                            onSearch={props.onPrescriptionSearch}
                            isBusy={props.isSearchLoading}
                            inputError={props.inputError}
                        />
                    </div>
                </div>
            )}

            {props.variant === 'error' && props.onNavigate && (
                <div className="no-drug-results__button">
                    <Button
                        async
                        className="sm-full"
                        label={props.buttonLabel as string}
                        variant="outline-primary"
                        type="button"
                        dataGALocation="PrescriptionNotFound"
                        onClick={props.onNavigate}
                    />
                </div>
            )}
        </div>
    );
};

export default PrescriptionErrorOrNoResults;
